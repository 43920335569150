
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { CharactersBaseDialog } from './charactersbase'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Util } from '../../base/util.js'


export class CharactersDialog extends CharactersBaseDialog {
    constructor(props) {
        super(props);
        this.state.newemail = '';        
        this.state.newpass = '';
        this.state.newpass2 = '';
    }

    onFirstLoad() {
        this.selectInitialGame();
    }

    socialIcon(key, url) {
        let icon = '/images/login/' + key + '.png';
        return (<a href={url} key={key} target='_blank' rel='noopener noreferrer' style={{display: 'inline-block', padding: '10px', verticalAlign: 'top'}}><img src={icon} alt='' /></a>);
    }

    renderGameInfo() {
        let nex = this.props.nexus;
        let gi = nex.gameinfo();
        let url = gi.files_url();
        if (!url) return null;
        let server = gi.root_url();
        let visible = server ? server.replace('https://', '').replace('/', '') : null;

        let logo;
        if (gi.is_ire_game()) {
            logo = (<img src={url + '/images/logo.png'} alt='' />);
            if (server) logo = (<a href={server} rel='noopener noreferrer' style={{width:'300px'}} target='_blank'>{logo}</a>);
        } else {
            let name = gi.game_short();
            let server = gi.server + ':' + gi.port;
            if (!gi.port) server = '';
            logo = (<div style={{position:'absolute',width:'100%',bottom:0,left:0,textAlign:'center'}}><div key='name' className='character_list_gameinfo_name'>{name}</div><div key='server' className='character_list_gameinfo_server'>{server}</div></div>);
        }
        logo = (<div style={{height:'150px', width:'100%', position:'relative', textAlign: 'center'}}>{logo}</div>);

        let btnStyle = {backgroundColor:'rgba(92,98,101,0.75)', minWidth:'240px'};
        let buttons = [];
        if (gi.is_ire_game())
            buttons.push((<div key='btn_create' style={{margin:10}}><Button key='btn_create' variant='contained' style={btnStyle} onClick={ ()=>this.props.oncreation() }>Create New Character</Button></div>));
        if (nex.logged_in_nexus()) {
            buttons.push ((<div key='btn_add' style={{margin:10}}><Button key='btn_add' variant='contained' style={btnStyle} onClick={ ()=>this.addChar() }>Add a Character</Button></div>));
        } else {
            buttons.push ((<div key='btn_play' style={{margin:10}}><Button key='btn_add' variant='contained' style={btnStyle} onClick={ ()=>this.handleGameLogin(0) }>Play Existing Character</Button></div>));
        }
        buttons = (<div style={{display:'flex',flexDirection:'column',paddingTop:'30px',alignItems:'center'}}>{buttons}</div>);

        let icons = [];
        if (gi.facebook_url) icons.push (this.socialIcon ('facebook', gi.facebook_url()));
        if (gi.twitter_url) icons.push (this.socialIcon ('twitter', gi.twitter_url()));
        if (gi.discord_url) icons.push (this.socialIcon ('discord', gi.discord_url()));
            
        let socials = (<div style={{marginBottom: '0px', marginTop: '20px'}}>{icons}</div>);
        let gamelink = (<div><a style={{color:'#8284aa', fontSize:'1.3em'}} href={server} rel='noopener noreferrer' target='_blank'>{visible}</a></div>);
        let gamelinks = (<div style={{marginBottom: '20px', marginTop: '20px'}}>{gamelink}</div>);
        let padding_top = (<div style={{flex:1}} />);
        let padding_bottom = (<div style={{flex:1}} />);

        let res = (<div className='character_list_gameinfo_inner' style={{display:'flex'}}>{padding_top}{logo}{buttons}{padding_bottom}{socials}{gamelinks}</div>);
        return res;
    }

    renderCharList() {
        if (this.state.loading) return 'Loading ...';
        if (this.state.loadError) return this.state.loadError.toString();
        let chars = this.state.chars;

        let nex = this.props.nexus;
        let gid = nex.active_game_id();

        let cname = 'character_entry';
        let char_els = [];
        if (chars) {
            for (let idx = 0; idx < chars.length; ++idx) {
                let ch = chars[idx];
                if (ch.game !== gid) continue;  // only show the currently selected game
                let cid = ch.cid;
                let btn_play = (<Button key='btn_play' className='greenButton' variant='contained' onClick={ ()=>this.handleGameLogin(cid) }>PLAY</Button>);
                let btn_settings = (<FontAwesomeIcon className='character_settings' icon={['fad', 'gear']} aria-label="Settings" onClick={() => this.openSettings(cid)} />);
                btn_settings = (<Tooltip title={'Edit Settings'}><span>{btn_settings}</span></Tooltip>);
                let btn_delete = (<FontAwesomeIcon className='character_delete' icon={['fad', 'trash-can']} aria-label="Remove" onClick={() => this.removeChar(cid)} />);
                btn_delete = (<Tooltip title={'Delete'}><span>{btn_delete}</span></Tooltip>);
                let btns = (<ListItemSecondaryAction>{btn_play}<span>&nbsp;&nbsp;</span>{btn_settings}{btn_delete}</ListItemSecondaryAction>);
                let second = '';
                if (ch.level) second = 'Level ' + ch.level;
                if (ch.class) second += ' ' + Util.ucfirst (ch.class);

                char_els.push ((<ListItem className={cname} button key={'char-'+ch.cid} onClick={ ()=>this.handleGameLogin(cid) }><ListItemText style={{color:'white'}} primary={Util.ucfirst(ch.name)} secondary={second} />{btns}</ListItem>));
            }
        }
        if (!char_els.length) {
            let linkSt = {textDecoration:'underline',cursor:'pointer'};
            let linkLogin = (<a style={linkSt} onClick={()=>this.props.onlogin()}>{'Log into Nexus'}</a>);
            let linkRegister = (<a style={linkSt} onClick={()=>this.props.onregister()}>{'Create an Account'}</a>);
            let noChars;
            if (nex.logged_in_nexus())
                noChars = (<span>{this.noCharsText()}</span>);
            else
                noChars = (<span>{this.noCharsText()}{' '}{linkLogin}<span>{' or '}</span>{linkRegister}.</span>);
            char_els.push ((<ListItem className={cname} key={'char-none'}><ListItemText style={{color:'white'}} primary={noChars} /></ListItem>));
        }
        
        return (<div className='character_list_entries' key='character_list_entries'><List key='characters_list'>{char_els}</List></div>);
    }

    renderAccount() {
        let nex = this.props.nexus;
        let btnStyle = {marginTop:'10px'};

        // Character count
        let chars = this.state.chars;
        let charcount = chars ? chars.length : 0;
        let chars_line = (<div>{'Characters: '}<span style={{fontWeight:'bold'}}>{charcount}</span></div>);
        
        // E-mail + ability to change it
        let email = nex.nexus_email();
        let email_field = (<TextField key='email' label='New e-mail' value={this.state.newemail} onChange={(e)=>{ this.setState({newemail: e.target.value}) } } />);
        let email_button = (<Button key='btn_email' variant='contained' style={btnStyle} onClick={ ()=>this.props.onacctchange(this.state.newemail, null, null) }>Change e-mail</Button>);
        let email_line = (<div>{'Account e-mail: '}<span style={{fontWeight:'bold'}}>{email}</span></div>);
        let email_change = (<div><div>{email_field}</div><div>{email_button}</div></div>);

        // Ability to set a new password
        let pass_field = (<TextField key='pwd' label='New password' type='password' value={this.state.newpass} onChange={(e)=>{ this.setState({newpass: e.target.value}) } } />);
        let pass2_field = (<TextField key='pwd' label='Confirm password' type='password' value={this.state.newpass2} onChange={(e)=>{ this.setState({newpass2: e.target.value}) } } />);
        let pass_button = (<Button key='btn_pwd' variant='contained' style={btnStyle} onClick={ ()=>this.props.onacctchange(null, this.state.newpass, this.state.newpass2) }>Change password</Button>);
        let pass_change = (<div><div>{pass_field}</div><div>{pass2_field}</div><div>{pass_button}</div></div>);

        // Ability to delete the account
        let delete_button = (<Button key='btn_del' variant='contained' style={btnStyle} onClick={ ()=>this.props.onacctremove() }>Delete Account</Button>);
        let delete_msg1 = 'Click below if you would like to delete your Nexus account. This will not delete your actual characters, but all the Nexus settings and reflexes associated with your characters WILL BE LOST.';
        let delete_msg2 = 'You will be prompted to enter your current Nexus password.';
        let acct_delete = (<div><div>{delete_msg1}</div><div>{delete_button}</div><div style={{marginTop:'10px'}}>{delete_msg2}</div></div>);

        let btn_back = (<Button key='btn_back1' variant='contained' style={btnStyle} onClick={ ()=>this.onCloseAccount() }>Back to Character List</Button>);
        let btn_back2 = (<Button key='btn_back2' variant='contained' style={btnStyle} onClick={ ()=>this.onCloseAccount() }>Back to Character List</Button>);

        let main = (<div className='account_info' style={{textAlign:'center'}}>{chars_line}{email_line}{btn_back}<hr /><h4>Change Account E-mail</h4>{email_change}<hr /><h4>Change Account Password</h4>{pass_change}<hr /><h4>Delete Nexus Account</h4>{acct_delete}<hr /><div>{btn_back2}</div></div>);
        return main;
    }

    renderContent() {
        let topbuttons = this.renderTopButtons();
        let games = this.renderGameSelector();
        let lst, list_header, ginfo;
        
        if (this.state.showAccount) {
            lst = this.renderAccount();
            list_header = (<h4>Account Information</h4>);
        } else {
            ginfo = this.renderGameInfo();
            lst =  this.renderCharList();
            list_header = (<h4>Character List</h4>);
        }

        let games_el = (<div id='character_list_games' className='character_list_games'>{games}</div>);
        let left = (<div id='character_list_left' className='character_list_left'>{topbuttons}{games_el}</div>);
        let center = ginfo ? (<div id='character_list_gameinfo' className='character_list_gameinfo'>{ginfo}</div>) : null;
        let right = (<div id={'character_list_right'} className='character_list_right'>{list_header}{lst}</div>);
        let main = (<div className='character_list'>{left}{center}{right}</div>);
        return main;
    }

    renderTopButtons() {
        let nex = this.props.nexus;
        let buttons = [];
        let style = {backgroundColor:'rgba(92,98,101,0.75)', marginRight:'5px', marginLeft: '5px'};
        if (nex.logged_in_nexus()) {
            // nothing
        } else if (this.props.loggingin) {
            buttons.push ('Logging in ...');
        } else {
            buttons.push ((<Button key='btn_login' variant='contained' style={style} onClick={()=>this.props.onlogin()}>Login to Nexus</Button>));
//            buttons.push ((<Button key='btn_register' variant='contained' style={style} onClick={()=>this.props.onregister()}>{'Create Account'}</Button>));
        }
        if (buttons.length)
            return (<div className='top_buttons'>{buttons}</div>);
        return null;
    }

    renderBottomBar() {
        let nex = this.props.nexus;
        let buttons = [];
        let style = {backgroundColor: 'rgba(0,0,0,1)', marginRight:'10px'};
        buttons.push ((<Button key='btn_help' variant='contained' style={style} onClick={()=>this.onhelp()}>{'Help'}</Button>));
        if (nex.logged_in_nexus()) {
            buttons.push ((<Button key='btn_account' variant='contained' style={style} onClick={()=>this.onAccount()}>{this.state.showAccount ? 'Characters' : 'Account'}</Button>));
            buttons.push ((<Button key='btn_logout' variant='contained' style={style} onClick={()=>this.props.onlogout()}>{'Logout'}</Button>));
        }
        let version = 'Version: ' + nex.client_version();
        version = (<div style={{fontSize:'0.8em'}}>{version}</div>);
        let logged = (<div style={{color:'white'}}>{this.loggedInText()}</div>);
        let apps = [];
        let img_google = (<img src="/images/bottom/play-store.png" />);
        let img_ios = (<img src="/images/bottom/apple-store.png" />);
        img_google = (<a href="https://play.google.com/store/apps/details?id=com.ironrealms.nexus" target="_blank" style={{paddingRight:5}}>{img_google}</a>);
        img_ios = (<a href="https://apps.apple.com/us/app/nexus-mud-client/id1641739522" target="_blank" style={{paddingRight:5}}>{img_ios}</a>);
        apps.push(img_google);
        apps.push(img_ios);

        return (<div className='characters_bottom'>{buttons}{logged}<div className='characters_bottom_middle'></div>{apps}{version}</div>);
    }

    render() {
        if (this.state.settingsdlg) return this.renderSettings();

        let nex = this.props.nexus;

        let maincontent = this.renderContent();
        let bottom = this.renderBottomBar();
        let content = (<div key="characters_wrapper" style={{display:'flex',flexDirection:'column',height:'100%'}}>{maincontent}{bottom}</div>);

        let backdropStyle={backgroundSize: 'cover', backgroundImage: 'url("/images/login/login-background.jpg")'};

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                className='characters_dialog'
                PaperProps={{style: {overflow:'visible', minHeight:'500px', backgroundColor: 'rgba(0,0,0,0.2)'} } }
                aria-labelledby="characters-dialog"
                disableEscapeKeyDown={true}
                BackdropProps={{style:backdropStyle}}>
            <DialogTitle id="character-dialog-title">Nexus</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
            </DialogActions>
            </Dialog>
        );
    }
}
