
import { CreationBaseDialog } from './creationbase.js'
import { Util } from '../../base/util.js'

import React from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { GameSelector } from '../gameselector'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../css/creation.css'

export class CreationDialog extends CreationBaseDialog {
    constructor(props) {
        super(props);
        this.listselref = React.createRef();
    }

    componentDidUpdate() {
        if (this.listselref.current) this.listselref.current.scrollIntoView({block: "end", inline: "nearest"});
    }

    // returns a promise
    fetchFile(url, params=undefined) {
        if (params) {
            let encoded = [];
            for (let pname in params) {
                encoded.push(pname+'='+encodeURIComponent(params[pname]));
            }
            url += '?' + encoded.join('&');            
        }
        return window.fetch(url, { headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 }});
    }

    renderListItem(stage, type, e, disabled) {
        let names = this.element_split_name(e);
        let name = names[0];
        let sel = (this.state.selection[type] === e.id);
        let ref = sel ? this.listselref : null;
        let avatar = e.thumbImageURL ? (<ListItemAvatar><Avatar src={e.thumbImageURL} /></ListItemAvatar>) : null;
        let res = (<ListItemButton disabled={disabled} ref={ref} key={type+'-'+e.id} selected={sel} onClick={(ev)=>{ if (!disabled) this.onListItemClick(type, e); } } >{avatar}<ListItemText primary={name} /></ListItemButton>);
        if (disabled) res = (<Tooltip key={type+'-'+e.id} title={'Your selected class cannot join ' + name + '.'}>{res}</Tooltip>);
        return res;
    }

    renderListDetails(type, e, btn_next, btn_back) {
        let title = this.element_title (type, e);
        let title_div = (<div className='creation_details_title'>{title}</div>);
        let subtitle = this.element_subtitle (type, e);
        let subtitle_div = subtitle ? (<div className='creation_details_subtitle'>{subtitle}</div>) : null;
        let gender = this.element_genderreq(type, e);
        let gender_div = gender ? (<div className='restriction_warning'>{this.element_genderreq_text(type, e)}</div>) : null;
        let restrict = this.element_restriction(type, e);
        let restrict_div = restrict ? (<div className='restriction_warning'>{this.element_restriction_text(type, e)}</div>) : null;
        let choice_text = this.creation_stage_info(type);
        let choice = (<div id={type + '_choice'} className='creation_choice_text'>{choice_text}</div>);

        let image = (<img src={e.imageURL} alt='' className='creation_details_image' />);
        image = (<div className='creation_details_image_wrapper'>{image}{choice}</div>);

        let descs = [];
        if (e.blurb) descs = e.blurb.split ("\n");
        else if (e.desc) descs = e.desc.split ("\n");
        let descs_el = [];

        for (let idx = 0; idx < descs.length; ++idx)
        {
            descs_el.push ((<span key={'desc'+idx} dangerouslySetInnerHTML={{__html:'<span>'+descs[idx]+'</span>'}}></span>));
            descs_el.push ((<br style={{margin:'8px'}} key={'br'+idx} />));
        }
        let desc = (<div className='creation_details_text'>{title_div}{subtitle_div}{gender_div}{restrict_div}{descs_el}</div>);

/*
        let idx = this.listGetNext(type);
        let what = Util.ucfirst(type);
        let btn_left = (idx === null) ? null : (<Button variant='contained' onClick={()=>this.listPreviousItem(type)}>{'Previous '+what}</Button>);
        idx = this.listGetPrevious(type);
        let btn_right = (idx === null) ? null : (<Button variant='contained' onClick={()=>this.listNextItem(type)}>{'Next '+what}</Button>);
*/
        let buttons = (<div className='creation_details_prev_next'>{btn_next}{btn_back}</div>);
        

        let center = (<div className='creation_details_center'>{image}{desc}</div>);
        let main = (<div className='creation_details_main'>{center}</div>);
        return (<div className='creation_details_wrap'>{main}{buttons}</div>);
    }

    renderList(stage, type) {
        if (!this.state.data) return null;
        let items = this.state.data[type];
        if (!items) items = [];

        let listitems = [];
        let groups = {};
        let selitem = null;
        for (let i = 0; i < items.length; ++i)
        {
            let e = items[i];
            let id = e.id;
            let disabled = false;
            if (!this.valid_choice(type, id)) disabled = true;

            if (this.state.selection[type] === id) selitem = e;
            let item = this.renderListItem(stage, type, e, disabled);

            var grp = this.display_list_group(e, type);
            if (grp) {
                if (!groups[grp]) groups[grp] = [];
                groups[grp].push (item);
            } else
                listitems.push (item);
        }

        // append the elements from each group
        for (let g in groups) {
            let header = (<ListSubheader disableSticky={true}>{g}</ListSubheader>);
            listitems.push(header);
            for (let j = 0; j < groups[g].length; ++j)
                listitems.push (groups[g][j]);
        }

        let btn_back = this.renderBackButton();
        let btn_next = this.renderNextButton();

        let item_details = null;
        if (selitem) item_details = this.renderListDetails(type, selitem, btn_next, btn_back);

        let header_text = this.creation_list_header(type);
        if (header_text) header_text = Util.ucfirst (header_text);

        let list_header = (<div id={type + '_list_header'} className='creation_list_header'>{header_text}</div>);
        let list = (<List id={type + '_list'} className='creation_list'>{listitems}</List>);
        let details = (<div key={type + '_details'} id={type + '_details'} className='creation_details'>{item_details}</div>);
        let left = (<div key={type + '_left'} id={type + '_left'} className='creation_left'>{list_header}{list}</div>);
        let header = (<h2 className='creation_stage_title'>{this.creation_title(stage)}</h2>);
        let title = (<div className='creation_subtitle'>{'Create A New Character'}</div>);
        let right = (<div key={type + '_cright'} id={type + '_center_right'} className='creation_center_right'>{title}{header}{details}</div>);
        let res = (<div className='creation_list_main'><div className='creation_list_center'>{left}{right}</div></div>);
        return res;
    }

    gridel(key, el, row, col, rowcount=1, colcount=1) {
        let colspec = col + ' / ' + (col + colcount);
        let rowspec = row + ' / ' + (row + rowcount);
        return (<div key={key} style={{gridColumn: colspec, gridRow: rowspec}}>{el}</div>);
    }

    gridlabel(key, label, row, col, rowcount=1, colcount=1) {
        let el = (<FormLabel component="legend">{label}</FormLabel>);
        return this.gridel(key, el, row, col, rowcount, colcount);
    }

    renderNameForm(stage) {
        let t = this;
        let name_error = t.state.nameAvailMessage;
        if (!name_error) name_error = t.state.nameMessage;
        let name_el = (<TextField autoFocus key="charname" label="Choose Your Name" FormHelperTextProps={{component:'div'}} value={this.state.name} onChange={(e)=>t.onNameChange(e.target.value, 'name')} required={true} error={t.state.errorName || t.state.errorNameAvail} helperText={name_error || ' '} />);
        let name_hint = (<span className='creation_name_hint'>{t.creation_name_hint()}</span>);
        let generate_el = (<Button variant='contained' onClick={()=>t.generateName()}>Random Name</Button>);

        let pass_el = (<TextField key="pass" label="Choose Password" FormHelperTextProps={{component:'div'}} type='password' value={this.state.pass} onChange={(e)=>t.onNameChange(e.target.value, 'pass')} required={true} error={t.state.errorPass} helperText={t.state.passMessage || ' '} />);
        let pass2_el = (<TextField key="pass2" label="Confirm Password" FormHelperTextProps={{component:'div'}} type='password' value={this.state.pass2} onChange={(e)=>t.onNameChange(e.target.value, 'pass2')} required={true} error={t.state.errorPass2} helperText={t.state.pass2Message || ' '} />);
        let email_el = (<TextField key="email" label="E-mail Address" FormHelperTextProps={{component:'div'}} value={this.state.email} onChange={(e)=>t.onNameChange(e.target.value, 'email')} required={true} error={t.state.errorEmail} helperText={t.state.emailMessage || ' '} />);
        let tos_text = (<span>I agree to the <a href={'https://'+t.props.nexus.gameinfo().server_name()+'/tos'} target='_blank' rel='noopener noreferrer'>Terms of Service</a> *</span>);
        let tos_el = (<FormControlLabel label={tos_text} control={<Checkbox checked={t.state.tos} onChange={(e)=>t.onNameCheckbox(e.target.checked, 'tos')} />} />);
        let nl_game_text = 'Send me the '+this.props.nexus.gameinfo().game_short()+' newsletter.';
        let nl_game_el = (<FormControlLabel label={nl_game_text} control={<Checkbox checked={t.state.nl_game} onChange={(e)=>t.onNameCheckbox(e.target.checked, 'nl_game')} />} />);
        let nl_ire_text = 'Send me the Iron Realms newsletter.';
        let nl_ire_el = (<FormControlLabel label={nl_ire_text} control={<Checkbox checked={t.state.nl_ire} onChange={(e)=>t.onNameCheckbox(e.target.checked, 'nl_ire')} />} />);

        let els = [ ];
        let row = 1;
        let name_label = this.gridlabel('name_label', 'NAME', row, 1);
        let name = this.gridel('el_name', (<div>{name_el}<br />{name_hint}</div>), row, 2);
        row++;
        let generate = this.gridel('el_generate', generate_el, row, 2);
        els.push(name);
        els.push(name_label);
        els.push(generate);

        let gender;
        if (this.gender_enforced()) {
            gender = this.gender_enforced_text();
        } else {
            // gender selector
            let g = this.genders();
            let glist = [];
            for (let idx = 0; idx < g.length; ++idx)
                glist.push((<FormControlLabel key={'gender-'+idx} value={g[idx]} control={<Radio />} label={g[idx]} />));
            gender = (<RadioGroup row value={t.state.gender} area-label='gender' onChange={(e)=>t.onGenderChange(e.target.value)} >{glist}</RadioGroup>);
        }
        row++;
        gender = this.gridel('el_gender', gender, row, 2);
        let gender_label = this.gridlabel('gender_label', 'GENDER', row, 1);
        els.push(gender);
        els.push(gender_label);

        row++;        
        let pass = this.gridel('el_pass', pass_el, row, 2);
        let pass_label = this.gridlabel('pass_label', 'PASSWORD', row, 1);
        row++;        
        let pass2 = this.gridel('el_pass2', pass2_el, row, 2);
        let pass2_label = this.gridlabel('pass2_label', 'CONFIRM', row, 1);
        els.push(pass);
        els.push(pass_label);
        els.push(pass2);
        els.push(pass2_label);

        let age, age_label;
        if (t.show_age()) {
            let limits = t.age_limits();
            let age_el1 = (<Slider value={t.state.age} className='creation_age_slider' min={limits[0]} max={limits[1]} step={1} onChange={(e,val)=>t.onAgeChange(val)} />);
            let age_el2 = (<Input value={t.state.age} className='creation_age_input' onChange={(e)=>t.onAgeInput(e.target.value)} onBlur={(e)=>t.onAgeInputBlur(e.target.value)} inputProps={{min:limits[0],max:limits[1],type:'number'}}/>);
            row++;
            age = this.gridel('el_age', (<div>{age_el1}<br />{age_el2}</div>), row, 2);
            age_label = this.gridlabel('age_label', 'AGE', row, 1);
            els.push(age);
            els.push(age_label);
        }
        let email_info = '';
        if (t.state.emailChecked) {
            if (t.state.emailRegistered) email_info = 'Your character will be added to the existing registration.';
            else email_info = 'This is a new registration. Please enter your information below.';
        } else {
            if (t.emailcheck_running) email_info = 'Checking your registration ...';
            else email_info = '';
        }

        row++;
        let email = this.gridel('el_email', (<div style={{marginBottom:'15px'}}>{email_el}<br />{email_info}</div>), row, 2);
        let email_label = this.gridlabel('email_label', 'E-MAIL', row, 1);
        els.push(email);
        els.push(email_label);

        // Do we want to show regis data?
        if (t.state.emailChecked && (!t.state.emailRegistered)) {
            row++;        
            let firstname_el = (<TextField key="firstname" label="First Name" FormHelperTextProps={{component:'div'}} value={this.state.firstName} onChange={(e)=>t.onNameChange(e.target.value, 'firstName')} required={true} error={t.state.errorFirstName} />);
            let firstname = this.gridel('el_firstname', firstname_el, row, 2);
            let firstname_label = this.gridlabel('firstname_label', 'FIRST NAME', row, 1);
            els.push(firstname);
            els.push(firstname_label);

            row++;        
            let lastname_el = (<TextField key="lastname" label="Last Name" FormHelperTextProps={{component:'div'}} value={this.state.lastName} onChange={(e)=>t.onNameChange(e.target.value, 'lastName')} required={true} error={t.state.errorLastName} />);
            let lastname = this.gridel('el_lastname', lastname_el, row, 2);
            let lastname_label = this.gridlabel('lastname_label', 'LAST NAME', row, 1);
            els.push(lastname);
            els.push(lastname_label);

            row++;        
            let dob_el = (<TextField key="dob" label="Date of Birth" FormHelperTextProps={{component:'div'}} value={this.state.DOB} onChange={(e)=>t.onNameChange(e.target.value, 'DOB')} required={true} error={t.state.errorDOB} helperText={t.state.DOBMessage || ' '}/>);
            let dob = this.gridel('el_dob', dob_el, row, 2);
            let dob_label = this.gridlabel('dob_label', 'DATE OF BIRTH', row, 1);
            els.push(dob);
            els.push(dob_label);
        }

        row++;
        let tos = this.gridel('el_tos', tos_el, row, 1, 1, 2);
        els.push(tos);
        row++;
        let nl_game = this.gridel('el_nl_game', nl_game_el, row, 1, 1, 2);
        els.push(nl_game);
        row++;
        let nl_ire = this.gridel('el_nl_ire', nl_ire_el, row, 1, 1, 2);
        els.push(nl_ire);
        
        let textlines = this.creation_nameform_text().split("\n");
        let image = this.creation_nameform_image();
        let textlines_els = [];
        for (let idx = 0; idx < textlines.length; ++idx) {
            if (idx) textlines_els.push ((<br/>));
            textlines_els.push (textlines[idx]);
        }
        let right = (<div className='creation_name_right'><div><img className="creation_name_image" src={image} width={'300px'} alt='' /></div><div style={{width:'300px'}}>{textlines_els}</div></div>);
        right = this.gridel('el_image', right, 1, 3, row - 1, 1);
        els.push(right);

        row++;
        let btn_next = this.gridel('btn_next', (<div style={{textAlign: 'left', paddingLeft: '150px'}}>{this.renderNextButton(true)}</div>), row, 1, 1, 3);
        els.push(btn_next);
        row++;
        let btn_back = this.gridel('btn_back', (<div style={{textAlign: 'left', paddingLeft: '150px'}}>{this.renderBackButton(true)}</div>), row, 1, 1, 3);
        els.push(btn_back);

        row++;
//        let autofill = this.gridel('autofill', (<div>&nbsp;</div>), row, 1);

        let columns = [];
        for (let i = 0; i < row; ++i) {
            if (i === row - 1)  // the last row takes all the remaining space
                columns.push ('auto');
            else
                columns.push ('min-content');
        }
        
        let main = (<div className='creation_name_main' style={{gridTemplateRows: columns.join(' ')}}>{els}</div>);
        let header = (<h2 className='creation_stage_title'>{this.creation_title(stage)}</h2>);
        let res = (<div className='creation_name_wrapper'>{header}{main}</div>);
        return res;
    }

    renderGameSelector() {
        let nex = this.props.nexus;
        let chars = this.props.chars;
        let landscape = nex.platform().is_landscape();  // needed so that the dialog updates when we rotate the device
        let sel = (<GameSelector nexus={nex} landscape={landscape} chars={chars} onSelect={ (gameID, info) => this.onGameSelect(gameID, info) } />);
        return sel;
    }

    renderBackButton(lastPage=false) {
        let style = {minWidth: 150};
        return (<Button key='back' variant="contained" style={style} onClick={() => this.btnBack()} >Back</Button>);
    }

   
    renderNextButton(lastPage=false) {
        let t = this;
        let next_caption = this.nextButtonCaption(false);

        let style = {minWidth: 150};
        if (lastPage) {
            style['marginBottom'] = '10px';
        }
        return (<Button key='next' variant="contained" style={style} onClick={() => t.btnNext()} color="secondary" disabled={this.state.failed}>{next_caption}</Button>);
    }

    render() {
        let t = this;
        let stage = t.state.stage;

        let subtitle = null;
        let content = null;

        let title = 'Nexus';
        if (t.state.loadFailed) {
            subtitle = 'Loading failed';
            content = (<div className='creation_loading'><div>{t.state.loadFailed}</div><div>{this.renderBackButton()}</div></div>);
        } else if (!t.state.data) {
            subtitle = 'Character Creation';
            content = 'Loading ...'
            content = (<div className='creation_loading'><div>Loading ...</div><div>{this.renderBackButton()}</div></div>);
        } else {
            content = this.renderContent(stage);
        }

        let games = this.renderGameSelector();
        games = (<div className='creation_list_games'><div className='creation_list_games_inner'>{games}</div></div>);

        if (subtitle) subtitle = (<div className='creation_subtitle'>{subtitle}</div>);
        content = (<div className='creation_right'>{subtitle}{content}</div>);
        content = (<div className='creation_content'>{games}{content}</div>);

        let backdropStyle={backgroundSize: 'cover', backgroundImage: 'url("/images/login/login-background.jpg")'};
        return (
            <Dialog
                className='creation_dialog'
                fullScreen
                open={this.props.open}
                aria-labelledby="creation-dialog"
                PaperProps={{style: {backgroundColor: 'rgba(0,0,0,0.4)'} }}
                disableEscapeKeyDown={true}
                BackdropProps={{style:backdropStyle}}>
            <DialogTitle id="creation-dialog">{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            </Dialog>
        );
    }

}
