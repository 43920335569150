// the main react component

import React from "react";

import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { settings: props.nexus.settings() };
  }

  updateNexusRef() {
    this.setState({ settings: this.props.nexus.settings() });
  }

  render() {
    let settings = this.state.settings;
    let reverted = settings.reverted;
    let AppTheme = createTheme(adaptV4Theme({
      typography: {
        fontFamily: [
          'Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'
        ].join(','),
      },
      palette: {
        mode: reverted ? "light" : "dark",
        secondary: {
          main: reverted ? '#00ff00' : '#00b300',
        },
        background: {
          default: reverted ? '#ffffff' : '#000000',
          header: reverted ? '#ffffff' : '#000000',
        },
      },
      overrides: {
        MuiBackdrop: {
            root: {
                backgroundSize: 'cover',
            }
        },
        MuiAppBar: {
          root: {
              zIndex: 0,
          },
          colorPrimary: {
            backgroundColor: reverted ? '#aaaaaa' : '#333333',
          },
        },
        MuiButton: {
            root: {
                borderColor: reverted ? '#000000' : '#aaaaaa',
                borderWidth: 1,
                borderStyle: 'solid',
                backgroundColor: '#5c6265',
                textTransform: 'none',
                fontSize: '1em',
                color: 'white',
                '&:hover': {
                    color: 'black',
                    backgroundColor: 'white !important',
                },
            },
            contained: {
                borderColor: reverted ? '#000000' : '#aaaaaa',
                borderWidth: 1,
                borderStyle: 'solid',
                backgroundColor: '#5c6265',
                color: 'white',
                '&:hover': {
                    backgroundColor: 'white !important',
                    color: 'black',
                },
            },
            creation: {
                borderColor: reverted ? '#000000' : 'white',
                borderWidth: 1,
                borderStyle: 'solid',
                backgroundColor: '#5c6265',
                color: 'white',
                '&:hover': {
                    backgroundColor: 'white !important',
                    color: 'black',
                },
            },
            outlined: {
                '&:hover': {
                    color: 'white',
                },
            },
            outlinedSizeSmall: {
                fontSize: '0.9em',
            },
        },
        MuiTabs: {
          indicator: {
            backgroundColor: '#808080',
          },
        },
        MuiTab: {
          root: {
            textTransform: "none",
            padding: '6px',
            fontSize: '1em',
          },
        },
        MuiTextField: {
          root: {
            minWidth: '200px',
          },
        },
        MuiPaper: {
          root: {
            backgroundColor: reverted ? '#cccccc' : '#252525',
            margin: '0px',
          },
        },
        MuiDialogTitle: {
          root: {
            textAlign: 'left',
            color: reverted ? '#000000' : '#ffffff',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#707070',
            backgroundColor: reverted ? '#cccccc' : '#252525',
          },
        },
        MuiTypography: {
            h6: {
                fontSize: '1.6em',
            },
            body1: {
                fontSize: '1em',
            },
            body2: {
                fontSize: '1em',
            },
        },
        MuiCardHeader: {
            root: {
                padding: '6px 16px 6px 16px',
                fontSize: '1.3em'
            }
        },
        MuiListSubheader: {
            root: {
                color: reverted ? '#000000' : '#ffffff',
                textTransform: 'uppercase',
                lineHeight: 'normal',
                marginTop: '15px',
                fontSize: '1em',
            },
            gutters: {
                paddingLeft: '0px',
                paddingRight: '0px',
            }
        },
        MuiListItem: {
            dense: {
                paddingTop: '0px',
                paddingBottom: '0px',
            }
        },
        MuiListItemText: {
            root: {
                color: reverted ? '#505050' : '#a0a0a0',
            }
        },
        MuiIconButton: {
            root: {
                fontSize: '1.7em',
            },
            sizeSmall: {
                fontSize: '1.125em',
            },
        },
        MuiCheckbox: {
          root: {
                paddingTop: '4px',
                paddingBottom: '4px',
          },
        },
        MuiInputBase: {
            root: {
                fontSize: '1em',
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1.1em',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '1em',
            },
        },
        MuiSlider: {
            root: {
                color: 'white',
            },
        }
      },
    }));
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
              <CssBaseline />
              {this.props.children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
  }
}
