
// game info - Imperian

export class GameInfoImperian {
    constructor() {

        this._gauges = [];
        this._gauges.push ({ name : 'health', 'desc' : 'Health', curvar : 'hp', maxvar : 'maxhp', percentage: true, color : '#ff0000', coloroff: '#7f7f7f',
            tooltip : 'Health represents your physical well-being. When your health reaches 0 you will die.'
        });
        this._gauges.push ({ name : 'mana', 'desc' : 'Mana', curvar : 'mp', maxvar : 'maxmp', percentage: true, color : '#5050ff', coloroff: '#7f7f7f',
            tooltip : 'Mana represents mental strength. When you are out of mana, you will not be able to perform most mental actions.'
        });
        
        this._balances = [];
        this._balances.push ({name : 'balance', class : 'bal', desc : 'Balance', curvar : 'bal',
            tooltip : 'Most physical abilities require that you have Balance and will take away your Balance for a few seconds after you use the ability.'
        });
        this._balances.push ({name : 'equilibrium', class : 'eq', desc : 'Equilibrium', curvar : 'eq',
            tooltip : 'Most magical or mental abilities will require that you have \'Equilibrium\' (or simply \'eq\') and will use it up for a few seconds when you use the ability.'
        });
    }

    server_name(secondary = false) {
        if (secondary) return 'ironrealms.com';
        return 'imperian.com';
    }
    
    server_port(encrypted, secondary = false) {
        if (secondary) return 12004;
        if (encrypted) return 443;
        return 80;
    }
    
    is_ire_game() { return true; }
    websock_dir() { return '/socket/'; }
    game_short() { return 'Imperian'; }
    game_name() { return 'imperian'; }
    game_long() { return 'Imperian, the Sundered Heavens'; };
    root_url() { return 'https://www.'+this.server_name(false)+'/' };
    vote_url() { return this.root_url()+'vote' };
    password_url() { return this.root_url()+'game/recover' };
    script_url() { return 'https://client.'+this.server_name(false)+'/' };
    files_url() { return '/games/' + this.game_name(); }
    text_creation() { return '2'; }
    button_count() { return 6; }
    css_style() { return 'standard'; }
    gauges() { return this._gauges; }
    balances() { return this._balances; }
    rift_name() { return 'Storage'; }
    command_rift_in() { return 'inr'; }
    command_rift_out() { return 'outr'; }
    money() { return 'Gold'; }
    intro_sound() { return 'imperian'; }
    avatar_count() { return 68; }
    discord_url() { return 'https://discord.gg/yjHU2ks'; }
    facebook_url() { return 'https://www.facebook.com/Imperian'; }
    twitter_url() { return 'https://twitter.com/ImperianIRE'; }
    
    google_analytics() { return 'UA-33274403-1'; }

    default_tabs() {
        return {
            container_1: [ 'map' ],
            container_2: [ 'affdef', 'skills', 'inventory' ],
            container_3: [ 'room', 'who', 'news' ],
            container_4: [ 'quests', 'all_comm', 'achievements' ],
            disabled: [ 'gauges', 'buttons' ],
            main_container: [ "output_main" ]
        };
    }

    // creation
    creation_stages() { return 4; }
    creation_valid_choice(type, id, creation_dlg) {
        if (type !== 'city') return true;

        let prof = creation_dlg.selected_obj('profession');
        if (!prof) return true;
        if (prof.any_group === 'Yes') return true;

        let city = creation_dlg.get_obj(type, id);        
        if (prof.group === city.group) return true;
        return false;
    }
    
    creation_step(step) {
        if (step === 1) return 'race';
        if (step === 2) return 'profession';
        if (step === 3) return 'city';
        if (step === 4) return 'name';
        return '';
    }

    creation_title(step) {
        if (step === 1) return 'Select Race';
        if (step === 2) return 'Select Profession';
        if (step === 3) return 'Select City';
        if (step === 4) return 'Complete Creation';
        return '';
    }

    creation_prepare_data(data) {
        let url = 'https://www.imperian.com/local/nexus_data/';
        let baseurl = url;

        for (let r in data['race']) {
            let img = data['race'][r].imageURL;
            data['race'][r].imageURL = url + 'races/' + img;
            data['race'][r].mobileImageURL = url + 'races/' + img;
            data['race'][r].thumbImageURL = baseurl + 'races/thumbnails/' + data['race'][r].thumbImageURL;
        }
        for (let c in data['profession']) {
            let name = data['profession'][c].name.toLowerCase();
//            let group = data['profession'][c].group.toLowerCase();
//            let pic = 'default.jpg';
//            if (group === 'magick faction') pic = 'classes/default-magick.jpg';
//            else if (group === 'anti-magick faction') pic = 'classes/default-antimagick.jpg';
//            else if (group === 'demonic faction') pic = 'classes/default-demonic.jpg';
//            else if (group === 'any faction') pic = 'classes/default-any.jpg';
            data['profession'][c].imageURL = url + 'classes/' + name + '.jpg?v=2';
            data['profession'][c].mobileImageURL = url + 'mobile/classes/' + name + '.jpg?v=2';
            data['profession'][c].thumbImageURL = baseurl + 'classes/thumbnails/' + name + '.jpg?v=2';
//            if ((name === 'deathknight') || (name === 'diabolist') || (name === 'predator')) {
//                data['profession'][c].imageURL = url + 'classes/' + name + '.jpg';
//            }
        }
        for (let c in data['city']) {
            let name = data['city'][c].name.toLowerCase();
            let pos1 = name.indexOf (' (');
            if (pos1 > 0) name = name.substr(0, pos1);
            data['city'][c].imageURL = url + 'cities/' + name + '.jpg';
            data['city'][c].mobileImageURL = url + 'mobile/cities/' + name + '.jpg';
            data['city'][c].thumbImageURL = baseurl + 'cities/thumbnails/' + name + '.jpg';
        }

        data['profession'].sort(function compare(a,b) {
            if (a.group < b.group) return -1;
            if (a.group > b.group) return 1;
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        return data;
    }

    display_list_group(e, type) {
        if (type === 'profession') return e.group;
        return null;
    }

    genders() { return [ 'Male', 'Female', 'Non-Binary' ]; }

    creation_genderreq (type, e) {
        if (type !== 'profession') return null;
        let req = e.gender;
        if (req === 'any') return null;
        return req;
    }
    creation_genderreq_text(type, e) {
        let g = this.creation_genderreq(type, e);
        if (!g) return '';
        return 'Your profession requires you to be ' + g + '.';
    }

    creation_restriction(type, e, creation_dlg) {
        if (type !== 'city') return null;
        // Can they join any other city?
        let allow = creation_dlg.obj_allowed_list(type);
        if (allow.length !== 1) return;
        return allow[0];
    }
    creation_restriction_text(type, e, creation_dlg) {
        let allow = this.creation_restriction(type, e, creation_dlg);
        if (!allow) return null;
        return 'Your profession can only join ' + allow.name + '.';
    }

    creation_data(creation_dlg) {
        let race = creation_dlg.selected_obj('race');
        let prof = creation_dlg.selected_obj('profession');
        let city = creation_dlg.selected_obj('city');
        if ((!race) || (!prof) || (!city)) return null;

        return "raceID\n" + race.id + "\nprofessionID\n" + prof.id + "\ncityID\n" + city.id;
    }

    creation_list_header(type, creation_dlg) {
        if (type === 'profession') return 'Select a profession:';
        if (type === 'city') {
            let cl = creation_dlg.selected_obj('profession');
            return cl.name + ' can join:';
        }
        return null;
    }
    
    creation_nameform_image(data, creation_dlg) {
        let race = creation_dlg.selected_obj('race');
        if (!race) return null;
        return race.imageURL;
    }


    // need to divide values by 11
    alter_gauge_var(variable, val) {
        return Math.floor ((val < 11) ? 1 : (val / 11));
    }

}
