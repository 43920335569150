import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


export class DisconnectedDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onKeyPress(e) {
        if (e.charCode !== 13) return;
        let setOpen = this.props.setOpen;
        setOpen(false);
        e.preventDefault();
    }

    render() {
        let open = this.props.open;
        let setOpen = this.props.setOpen;

        let text = this.props.reason;
        if (!text) text = 'Connection has been closed.';
        
        let st = { 'margin' : '5px', 'text-align' : 'center' };
        let elNotice = (<div style={st}>{text}</div>);
        let btnCharacters = <Button variant="contained" style={st} onClick={() => this.props.onCharacters()}>{'Show character list'}</Button>
        let btnReconnect = <Button variant="contained" style={st} onClick={() => this.props.onReconnect()}>{'Reconnect'}</Button>
        let btnDownload = <Button variant="contained" style={st} onClick={() => this.props.onDownload()}>{'Download log'}</Button>
        let content = (<div style={{display:'flex', flexDirection:'column'}}>{elNotice}{btnCharacters}{btnReconnect}{btnDownload}</div>);
        return (
            <Dialog open={open} onKeyPress={(e) => this.onKeyPress(e)} onClose={() => setOpen(false)} aria-labelledby="disconnected-dialog" style={{zIndex:8499}}>
            <DialogTitle id="disconnected-dialog">{'Connection closed'}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            </Dialog>
        );
    }
}
 
