
// game popups

import React from 'react';

import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import { useTheme } from '@mui/material';

import { NexusDraggable }  from '../nexusdraggable'

class RealContentWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderHeader(type, name) {
        return name;
    }

    renderTextContent(content) {
        return (<div style={{whiteSpace:'pre-wrap'}}>{content}</div>);
    }

    renderContent(type, content) {
        if (type === 'text') {
            return this.renderTextContent(content);
        }

        let nex = this.props.nexus;
        let gi = nex.gameinfo();
        if (gi.game_parse_content_window) {
            let res = gi.game_parse_content_window(nex, type, content);
            if (res === 'hide') return null;
            if (res !== undefined) return res;
        }

        nex.platform().log_raw ('Unknown content window type: ' + type);

        return null;
    }

    render() {
        let theme = this.props.theme;
        let offsetId = this.props.offsetId;
        let nex = this.props.nexus;

        let info = this.props.info;
        let id = info.id;
        let open = info.open;
        let name = info.name;
        let type = info.type;
        let content = info.content;
        console.log(info);
//        let width = info.width;
//        let height = info.height;

        let style = {backgroundColor: theme.palette.background.default, /*width: width+'px', height: height+'px',*/ position:'relative'};
        let hstyle = {backgroundColor: theme.palette.background.header};
        let cstyle = { position:'absolute', top:0, right:0 };
        let closeButton = (<IconButton aria-label="close" size='small' style={cstyle} onClick={() => this.props.onClose()} ><CancelIcon /></IconButton>);

        let header = this.renderHeader(type, name);
        content = this.renderContent(type, content);
        if (content === null) {  // we need to close this
            this.props.onClose();
            return null;
        }

        content = (<div className='content contentwindow_content'>{content}</div>);
        
        content = (<div style={style} id={'contentwindow-'+id} key={'contentwindow-'+id} className='contentWindow'><div style={hstyle} className='handle nexusDialogHeader'>{header}{closeButton}</div>{content}</div>);
        content = (<NexusDraggable handle='.handle' platform={nex.platform()} key={'contentwindow-handle-'+id} position={{x: 'center', y: 'center'}} offsetId={offsetId}>{content}</NexusDraggable>);
        let dlg = (<Popper aria-labelledby="content-window" key={'contentwindow-popper-'+id} open={open} placement='top-end' >{content}</Popper>);
        return dlg;
    }

}

export function ContentWindow(props) {
  const theme = useTheme();
  return <RealContentWindow {...props} theme={theme} />;
}
