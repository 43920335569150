
import React from 'react';

import { GameSelector } from '../gameselector'
import { Util } from '../../base/util.js'

import { SettingsDialog } from './settings'

export class CharactersBaseDialog extends React.Component {
    constructor(props) {
        super(props);

        this.firstLoad = true;
        this.logged = false;
        this.state = { charpass: '', settChecked: true, chars: null, loading: false, loadError: null, loadsett: true, cgame: null };
    }

    componentDidMount() {
        this._mounted = true;
        this.onFirstLoad();   // need to call this here too, so that we show the first game even if not logged in
        this.load();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    // Need to reload the games whenever the logged in status changes. The parent class sends a loggedin prop, which we don't use, it's merely so that we update.
    componentDidUpdate() {
        let nex = this.props.nexus;
        let logged = nex.logged_in_nexus();
        if (logged === this.logged) return;
        this.logged = logged;
        this.load();
    }

    loggedInText() {
        let nex = this.props.nexus;
        let acct = nex.nexus_email();
        return acct ? acct : 'Not logged in.';
    }

    // Selece the last played game, or the first game.
    selectInitialGame() {
        let nex = this.props.nexus;
        let gameIDs = nex.gameIDs();
        let id = gameIDs[0];
        // Check if we have a stored game.
        let existing = nex.platform().local_setting('IRE.Game');
        if (existing) existing = parseInt(existing);
        if (existing && gameIDs.indexOf(existing)) id = existing;
        // Check if the platform wants a default game
        let enforce = nex.platform().starting_game();
        if (enforce && gameIDs.indexOf(enforce)) id = enforce;
        this.onGameSelect(id, null);
    }

    selectGameIfNeeded() {
        let nex = this.props.nexus;
        let gid = nex.active_game_id();
        if (!gid) nex.platform().set_timeout (() => this.selectInitialGame(), 0);
    }

    noCharsText() {
        let nex = this.props.nexus;
        let gi = nex.gameinfo();
        let gname = gi.game_short();
        if (nex.logged_in_nexus()) {
            if (gi.is_ire_game()) {
                return 'You have no ' + gname + ' characters registered in Nexus. Use "Add a Character" to add an existing character, or "Create New Character" to create an entirely new character on this game.';
            }                
            return 'You have no ' + gname + ' characters registered in Nexus. Use "Add a Character" to add one. If the character does not actually exist on this game, you can then proceed to creating it by clicking on the PLAY button.';
        }
        return 'You are currently using Nexus as a guest. To save your character list, ';
    }

    load() {
        let nex = this.props.nexus;
        if (!nex.logged_in_nexus()) {
            this.setState({chars:[]});
            return;
        }

        let chars = this.props.chars;
        
        this.setState({ loading: true });
        chars.chars().then((list) => {
            if (!this._mounted) return;
            let st = { loading: false, chars: list };
            this.setState(st);
            if (this.firstLoad) {
                this.onFirstLoad();
                this.firstLoad = false;
            }
        }).catch((err) => {
            this.setState({ loading: false, loadError: err });
        });
    }

    getChar(cid) {
        let lst = this.state.chars;
        if (!lst) return null;
        for (let idx = 0; idx < lst.length; ++idx) {
            if (lst[idx].cid === cid) return lst[idx];
        }
        return null;  // nothing found
    }

    login(cid) {
        let char = this.getChar(cid);
        if (!char) return;
        
        this.props.onlogin (char.name, this.state.charpass, char.game, this.state.settChecked);
    }

    settSetChecked(ch) {
        this.setState ({settChecked: ch});
    }

    addChar() {
        this.props.onaddchar(this.props.chars, () => {
            this.load();
        });
    }

    renameChar(cid) {
        if (!cid) return;
        let ch = this.getChar(cid);
        if (!ch) return;
        this.props.onrenamechar(this.props.chars, cid, ch.name, () => {
            this.load();
        });
    }

    removeChar(cid) {
        if (!cid) return;
        let ch = this.getChar(cid);
        if (!ch) return;
        let nex = this.props.nexus;
        let gi = nex.gameinfo();
        let gname = gi.game_name();

        this.props.platform.confirm('Really Remove?', 'Do you really want to remove ' + Util.ucfirst(ch.name) + ' at ' + Util.ucfirst(gname) + ' from your Nexus account? This will not affect the actual character.', () => {
            let chars = this.props.chars;
            chars.char_remove(cid).then(()=>{
                this.load();
            })
            .catch((err) => this.props.platform.alert('Error removing character', String(err)));
        });
    }

    openSettings(cid) {
        let t = this;
        if (!cid) return;
        let ch = this.getChar(cid);
        if (!ch) return;

        let nex = this.props.nexus;
        nex.charname = ch.name;
        nex.current_game_id = ch.game;
        nex.load_system(null, false, (sys) => {
            if (!sys) {
                this.props.platform.alert('No settings', 'There are no settings stored for this character.');
                return;
            }
            t.setState({settingsdlg:true});
        });
    }

    handleGameLogin(cid) {
        let t = this;
        if (cid === 0) {
            let nex = t.props.nexus;
            let gi = nex.gameinfo();
            // If it's not our game, we can proceed to connecting. This is currently not supported by the interface, but adding it here in case we do add it later.
            if (!gi.is_ire_game()) {
                t.props.ongamelogin ('', t.state.loadsett);
                return;
            }

            // We'll need to fetch the name.
            let layout = nex.ui().layout();
            let gname = gi.game_name();
            layout.getText('Login to ', 'Enter the name of your character on ' + Util.ucfirst(gname) + ':', '', 'Character Name', 'Next', (name) => {
                t.props.ongamelogin (name, t.state.loadsett);
            });
            return;
        }

        let ch = t.getChar(cid);
        if (!ch) return;
        t.props.ongamelogin (ch.name, t.state.loadsett);
    }

    onGameSelect(gameID, info) {
        let nex = this.props.nexus;
        nex.set_current_game (gameID, info, true);
        this.setState({cgame: gameID,showAccount: false});  // so that things update, we don't really need that cgame
    }

    renderGameSelector(big=false) {
        let chars = this.props.chars;

        let nex = this.props.nexus;
        let logged = nex.logged_in_nexus();
        let landscape = nex.platform().is_landscape();  // needed so that the dialog updates when we rotate the device - mobile only
        let sel = (<GameSelector nexus={nex} chars={chars} landscape={landscape} bigscreen={big} loggedin={logged} onSelect={ (game, info) => this.onGameSelect(game, info) } />);
        return sel;
    }

    onCloseSettings() {
        let nex = this.props.nexus;
        nex.save_system();
        nex.set_default_settings();
        this.setState({settingsdlg:false});
    }
    
    renderSettings() {
        let nex = this.props.nexus;
        let onCancel = () => { this.onCloseSettings() };
        return ((<SettingsDialog key="settings_dialog" open={true} nexus={nex} onCancel={onCancel} modal={true} />));
    }

    onCloseAccount() {
        this.setState({showAccount:false});
    }

    onAccount() {
        if (this.state.showAccount)
            this.setState({showAccount:false});
        else
            this.setState({showAccount:true});
    }

    onhelp() {
        let nex = this.props.nexus;
        nex.ui().statusbar_feature('help');
    }
}
