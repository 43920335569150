 
import { LineBuffer } from '../base/lines.js'
import { Notifications } from './notifications.js'
import { Sounds } from './sounds'
import { LayoutManager } from './layout.js'
import { ButtonInfo } from './buttoninfo.js';
import { GaugeInfo } from './gaugeinfo.js';

export class Interface {
    constructor(nexus) {
        this._nexus = nexus;
    }


    // *** OBJECT DECLARATIONS START HERE ***

    // main output data buffer
    buffer() {
        var t = this;
        if (!t._buffer) {
            t._buffer = new LineBuffer();
            t._buffer.on_lines_changed = function() {
                if (t._buffer.output) t._buffer.output.updated();
            };
        }
        return this._buffer;
    }

    // communication buffer
    comm_buffer() {
        var t = this;
        if (!t._comm_buffer) {
            t._comm_buffer = new LineBuffer();
            t._comm_buffer.on_lines_changed = function() {
                if (t._comm_buffer.output) t._comm_buffer.output.updated();
            };
        }
        return this._comm_buffer;
    }

    // this holds button information, the UI is elsewhere
    buttons() {
        if (!this._buttons) {
            this._buttons = new ButtonInfo (this._nexus);
            let t = this;
            // this is a bit of an overkill as we only change buttons, but for now it's fine
            t._buttons.onchange = function() {
                t.layout().gmcp_updated();
                
            };
        }
        return this._buttons;
    }

    // Similarly, this is gauge information
    gauges() {
        if (!this._gauges) {
            this._gauges = new GaugeInfo (this._nexus);
            let t = this;
            // this too is a bit of an overkill, but for now it's fine
            t._gauges.onchange = function() {
                t.layout().gmcp_updated();
            };
        }
        return this._gauges;
    }

    notifications() {
        if (!this._notifications) this._notifications = new Notifications (this._nexus.settings(), this._nexus.platform());
        return this._notifications;
    }

    sounds() {
        if (!this._sounds) {
            var t = this;
            this._sounds = new Sounds(this._nexus);
            this._sounds.on_volume_changed = function(volume) {
                t._nexus.platform().on_volume_changed(volume);
            };
        }
        return this._sounds;
    }

    layout() {
        if (!this._layout) {
            this._layout = new LayoutManager (this._nexus);
        }
        return this._layout;
    }

    
    // *** OBJECT DECLARATIONS END HERE ***
    


    setup() {
        this.buffer().clear();
        this.redraw();
    }

    close_dialogs() {
        this.layout().close_dialogs();
    }

    show_characters() {
        this.close_dialogs();
        this.layout().use_default_tabs();
        this.redraw();
        
        this.layout().show_characters ();
    }

    redraw() {
        this.layout().gmcp_updated();  // this will redraw everything
    }

    apply_settings(s) {
        let limit = this._nexus.platform().real_mobile() ? s.scrollback_msg_limit : 0;
        this.buffer().set_line_limit(limit);
        this.comm_buffer().set_line_limit(limit);
        this._nexus.platform().apply_settings();
        this.layout().apply_settings(s);
        this.redraw();
    }

    on_activated() {
        this._nexus.platform().focus_input();
        this.notifications().set_focused(true);
    }
    
    on_deactivated() {
        this.notifications().set_focused(false);
    }

    want_compass() {
        let nex = this._nexus;
        let mobile = nex.platform().real_mobile();

        let s = nex.settings();
        return mobile ? s.movement_compass : s.movement_compass_web;
    }

    // handles clicks on various statusbar features
    statusbar_feature(f) {
        var t = this;
        if (f === 'help') {
            this._nexus.platform().open_url('https://nexus.ironrealms.com/');
            return;
        }
        if (f === 'logging-start') {
            let log = this._nexus.log();
            log.enable();
            t.redraw();
            return;
        }
        if (f === 'logging-stop') {
            let log = this._nexus.log();
            let data = log.logFromBuffer(true);
            if (data && data.length) log.downloadLog(data);
            log.disable();
            t.redraw();
            return;
        }
        if (f === 'logging-download') {
            let log = this._nexus.log();
            let data = log.logFromBuffer();
            log.downloadLog(data);
            return;
        }
        if (f === 'reset') {
            t._nexus.platform().confirm('Reset Interface', 'Are you sure you wish to reset the user interface?', 
                function() {
                    t.layout().use_default_tabs();
                    t.redraw();
                });
            return;
        }
        if (f === 'settings') {
            t.layout().toggle_settings();
            return;
        }
        if (f === 'exec-start') {
            t._nexus.fullstop = false;
            return;
        }
        if (f === 'exec-stop') {
            t._nexus.fullstop = true;
            return;
        }
        if (f === 'fullscreen') {
            t._nexus.platform().toggle_fullscreen();
        }
        if (f === 'msgs') {
            t._nexus.send_commands('msg list', true);
            return;
        }
        if (f === 'news') {
            t._nexus.send_commands('nstat', true);
            return;
        }
    }

    onMenuClick(id) {
        let t = this;
        // fullscreen
        if (id === 'fullscreen') {
            t._nexus.platform().toggle_fullscreen();
            return;
        }
        if (id.substring(0, 5) === 'copy ') {
            t._nexus.platform().copy_to_clipboard(id.substring(5));
            return;
        }
        let words = id.split(' ');
        // tab opening/closing
        if (words[0] === 'open_tab') {
            t.layout().activate_tab (words[1]);
            return;
        }
        if (words[0] === 'close_tab') {
            t.layout().deactivate_tab (words[1]);
            return;
        }
        // channel opening/closing
        if (words[0] === 'open_channel') {
            t.layout().open_channel (words[1], words[2], words[3]);
            return;
        }
        if (words[0] === 'close_channel') {
            t.layout().close_channel (words[1]);
            return;
        }
        // pin
        if (words[0] === 'pin') {
            t.layout().pin_tab (words[1]);
            return;
        }
        // unpin
        if (words[0] === 'unpin') {
            t.layout().unpin_tab (words[1]);
            return;
        }
        
        t.statusbar_feature(id);
    }

}
