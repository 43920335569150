
import { Util } from './util.js'
import { LineParser } from './line.js'

// a block of lines, usually ended with a prompt
// Has callbacks for various things found in the block
export class BlockParser {
    constructor(nexus) {
        this._nexus = nexus;
        this.on_special_display = function(type, lines, params){};
    }

    // one line can have the following variables set:
    // line = one TextLine
    // parsed_line - parsed line, filled in by this function
    // is_prompt - if it's actually a prompt
    // gag - if the line is to be gagged
    // monospace - if the line should be rendered in monospace
    // display - special display type, 'start' is true or false
    // channel - channel marker, 'start' is true or false

    // Parses a list of lines, returns a list to display/etc.
    // also triggers callbacks as needed.
    parse_lines (lines) {
        var res = [];
        if (lines == null) return res;
        if (lines.length === 0) return res;

        var gagging_channel = false;
        var monospace = false;
        var buffer = [];
        var buffertype = '';
        var parser = new LineParser (this._nexus.settings(), this._nexus.datahandler());
        var parser2 = undefined;

        for (var i = 0; i < lines.length; ++i) {

            // special display?
            if (lines[i].display) {
                var disp = lines[i].display;
                var start = lines[i].start;
                if (disp === 'fixed') {
                    monospace = start;
                    continue;
                }

                // we don't want to use/retain the existing state
                if (start) {
                    parser2 = new LineParser(this._nexus.settings(), this._nexus.datahandler());
                    buffer = [];
                    buffertype = disp;
                } else {
                    if (buffertype !== disp) {  // two different display types collide
                        buffertype = '';
                        buffer = [];
                        continue;   // let's just ignore the entire thing for now
                    }
                    var params = {};
                    if (buffertype === 'window') params['cmd'] = lines[i].cmd;
                    this.on_special_display (buffertype, buffer, params);
                    buffertype = '';
                    buffer = [];
                }
                continue;
            }

            // inside some display
            if (buffertype.length) {
                lines[i].parsed_line = parser2.parse_line (lines[i].line);
                buffer.push (lines[i]);
                continue;
            }

            // gag channels if requested
            if (lines[i].channel) {
                if (lines[i].start) {
                    var ch = lines[i].channel;
                    if (this._nexus.ui().layout().should_gag_channel(ch))
                        gagging_channel = true;
                }
                else
                    gagging_channel = false;
                continue;
            }
            if (gagging_channel) continue;

            // finally, the regular functionality
            if (lines[i].line !== undefined) {
                lines[i].parsed_line = parser.parse_line(lines[i].line);
                if (monospace && (!lines[i].is_prompt)) lines[i].monospace = true;
            }
            res.push (lines[i]);
        }
        return res;
    }
}




// A buffer of lines that automatically wipes old ones, handles tab completion, and so on. No displaying is provided here.
// One exists for the main buffer, and one for each channel.
export class LineBuffer {
    constructor() {
        this.lines = [];
        this.index = {};
        this.limit = 0;
        this.lineid = 0;

        // callbacks
        this.on_lines_changed = function(line){};
        this.output = null;
    }

    set_line_limit (limit) {
        if (limit < 0) limit = 0;
        this.limit = limit;
        let count = this.prune_lines();
        if (count) this.on_lines_changed();
    }

    // Adds a new block to the buffer. Gags are already removed.
    add_block (block) {
        for (let idx = 0; idx < block.length; ++idx)
        {
            var l = block[idx];

            this._fill_tab_completion(l);
            this.lineid++;
            l.id = this.lineid;
            this.lines.push (l);
            this.unread = true;
        }
        let pruned = this.prune_lines();
        if (block.length || pruned) this.on_lines_changed();
    }

    _fill_tab_completion(l) {
        if (l.gag) return;
        var pl = l.parsed_line;
        if (!pl) return;
        var text = pl.text();
        if ((!text) || (!text.length)) return;
        
        var chunks = text.split(' ');
        var words = [];
        for (var j = 0; j < chunks.length; ++j) {
            var w = chunks[j].trim();
            // trim everything not alphanumeric from beginning/end
            while (w.length && (!Util.is_alphanumeric(w.substr(0, 1)))) w = w.substr(1);
            while (w.length && (!Util.is_alphanumeric(w.substr(-1)))) w = w.substr(0, w.length - 1);
            if (w.length && (words.indexOf(w) < 0)) words.push(w);
        }
        l.tab_words = words;
    }

    words_for_tab_completion(prefix, linecount) {
        if (prefix.length < 2) return [];   // no expansion if we don't have at least two characters

        if (!linecount) linecount = 40;
        prefix = prefix.toLowerCase();
        var counted = 0;
        var res = [];
        for (var i = this.lines.length - 1; i >= 0; --i) {
            if (!this.lines[i].tab_words) continue;
            for (var j = 0; j < this.lines[i].tab_words.length; ++j) {
                var word = this.lines[i].tab_words[j];
                if ((word.toLowerCase().substr(0, prefix.length) === prefix) && (res.indexOf(word) < 0))
                    res.push(word);
            }
            counted++;
            if (counted >= linecount) break;
        }
        return res;
    }

    get_line_by_idx (idx) {
        if (idx < 0) return undefined;
        if (idx >= this.lines.length) return undefined;
        return this.lines[idx];
    }

    get_line_by_id (id) {
        var idx = this.index[id];
        if (!idx) return undefined;
        return this.get_line_by_idx (idx);
    }

    count() {
        return this.lines.length;
    }

    clear() {
        while (this.lines.length) {
            var line = this.lines.shift();
        }
        this.on_lines_changed();
    }
    
    prune_lines () {
        if (this.limit <= 0) return;   // if no limit is set, we do not prune, ever
        let count = 0;
        while (this.lines.length > this.limit) {
            count++;
            this.lines.shift();
        }
        return count;
    }


}


